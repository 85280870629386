<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Addresses
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <button
          @click="addAddress"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Address</span>
      </button>
    </div>

    <div class="flex flex-wrap w-full">
      <DataTable
          v-if="addresses.length > 0"
          class="w-full"
          :value="addresses"
          :rows="10"
          :paginator="true"
          :rowsPerPageOptions="[5, 10, 20]"
      >
        <Column
            v-for="col in columns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            :sortable="col.sortable"
            :headerStyle="{ width: col.width }"
        >
          <template v-slot:body="slotProps">
            <template v-if="col.slotName === 'action-slot'">
              <div class="flex space-x-4">
                <AppButton
                    color="blue"
                    label=""
                    :icon="true"
                    @click.native.stop="openEditAddress(slotProps)"
                >
                  <template v-slot:icon>
                    <Icon iconType="edit" />
                  </template>
                </AppButton>
                <AppButton
                    color="red"
                    label=""
                    :icon="true"
                    @click.native.stop="confirmDeleteAddress(slotProps)"
                >
                  <template v-slot:icon>
                    <Icon iconType="delete" />
                  </template>
                </AppButton>
              </div>
            </template>

            <template v-else>
              {{ slotProps.data[col.field] || "N/A" }}
            </template>
          </template>
        </Column>

        <template #empty>
          <p class="mx-auto my-16">There are no Addresses on this Contact.</p>
        </template>
      </DataTable>
    </div>

    <portal to="overlay-outlet">
      <panel
          :showing="addEditAddressPanelOpen"
          @close="closeAddEditAddress"
          :title="editingContact.contact_id ? 'Edit Contact' : 'Add Contact'"
      >
        <AddAddress
            :contact_id="contact_id"
            :address_id="selectedAddressId"
            :initial_address="selectedAddress"
            @refreshContact="onAddressSaved"
        />
      </panel>
    </portal>
  </div>
</template>

<script>
import Icon from "@/components/Icon.vue";
import AppButton from "@/components/Button.vue";
import notificationMixin from "@/mixins/notificationMixin";

const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Panel = () => import("@/components/Panel.vue");
const AddAddress = () => import("@/components/Contacts/AddAddress.vue");

export default {
  mixins: [notificationMixin],
  name: "Addresses",
  components: {
    AppButton,
    Icon,
    Notification,
    ConfirmAlert,
    Panel,
    AddAddress,
  },
  props: {
    addresses: Array,
    contact_id: String,
  },
  data() {
    return {
      addEditAddressPanelOpen: false,
      editingContact: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      columns: [
        { field: "type", header: "Address Type", sortable: true, width: "10%" },
        { field: "address1", header: "Address 1", sortable: true, width: "20%" },
        { field: "address2", header: "Address 2", sortable: true, width: "20%" },
        { field: "address3", header: "Address 3", sortable: true, width: "20%" },
        { field: "city", header: "City", sortable: true, width: "10%" },
        { field: "postcode", header: "Postcode", sortable: true, width: "10%" },
        {
          field: "action",
          header: "Actions",
          sortable: false,
          width: "10%",
          custom: true,
          slotName: "action-slot",
        },
      ],
      selectedAddressId: "",
      selectedAddress: null,
    };
  },
  methods: {
    addAddress() {
      this.editingContact = {};
      this.selectedAddressId = "";
      this.selectedAddress = null;
      this.addEditAddressPanelOpen = true;
    },

    async onAddressSaved() {
      this.closeAddEditAddress();
      await this.refreshContact();
    },

    closeAddEditAddress() {
      this.addEditAddressPanelOpen = false;
    },

    async refreshContact() {
      this.$emit("refreshContact");
    },

    openEditAddress(selectedRow) {
      if (selectedRow && selectedRow.data && selectedRow.data.address_id) {
        this.selectedAddressId = selectedRow.data.address_id;
        this.selectedAddress = selectedRow.data;
        this.addEditAddressPanelOpen = true;
      }
    },

    async confirmDeleteAddress(selectedRow) {
      if (!selectedRow || !selectedRow.data || !selectedRow.data.address_id) return;

      const addressId = selectedRow.data.address_id;
      const confirmation = await this.confirmAction({
        message: "Do you want to delete this address?",
        header: "Confirm Action",
        acceptLabel: "Yes",
        rejectLabel: "No",
      });

      if (confirmation) {
        try {
          if (addressId) {
            await this.ContactService.deleteAddress(this.contact_id, addressId);
            this.notifySuccess("Address deleted successfully");
            await this.refreshContact();
          } else {
            this.notifyError("Address ID is missing");
          }
        } catch (error) {
          this.notifyError("Couldn't delete the address");
          console.error(error);
        }
      }
    },
  },
};
</script>
